//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";

export default BaseCmsBlock.extend({
  name: "HowWorks",
  data() {
    return {};
  },
  components: {},
});
